<!-- 承揽协议pdf查看 -->
<template>
  <div>
    <div class="center">
      <van-nav-bar
        class="nar-bar-info"
        title="自雇者协议阅读"
        left-arrow
        @click-left="onClickLeft"
      />
      <div class="contor" v-if="flag">
        <van-button
          plain
          hairline
          color="#c9431f"
          type="primary"
          size="mini"
          style="cursor:pointer"
          @click="prev"
        >上一页</van-button>
        <span>
          <span v-text="page_num" /> /
          <span v-text="page_count" />
        </span>
        <van-button
          plain
          hairline
          color="#c9431f"
          type="primary"
          size="mini"
          style="cursor:pointer"
          @click="next"
        >下一页</van-button>
        <van-button
          plain
          hairline
          color="#c9431f"
          type="primary"
          size="mini"
          style="cursor:pointer"
          @click="addscale"
        >放大</van-button>
        <van-button
          plain
          hairline
          color="#c9431f"
          type="primary"
          size="mini"
          style="cursor:pointer"
          @click="minus"
        >缩小</van-button>
      </div>
      <canvas id="the-canvas" class="canvasstyle" />
    </div>
  </div>
</template>

<script>
let PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.min");

export default {
  components: {},
  data() {
    return {
      pdfUrl: "",
      pdfDoc: null, // pdfjs 生成的对象
      pageNum: 1, //
      pageRendering: false,
      pageNumPending: null,
      scale: 1.7, // 放大倍数
      page_num: 0, // 当前页数
      page_count: 0, // 总页数
      maxscale: 2.2, // 最大放大倍数
      minscale: 1.2, // 最小放大倍数
      flag: true //是否显示分页
    };
  },
  computed: {
    ctx() {
      const id = document.getElementById("the-canvas");
      return id.getContext("2d");
    }
  },

  methods: {
    onClickLeft() {
      window.history.go(-1);
    },
    init(pdfUrl) {
      let _this = this;
      // 初始化pdf
      PDFJS.getDocument(pdfUrl).then(function(pdfDoc_) {
        _this.pdfDoc = pdfDoc_;
        _this.page_count = _this.pdfDoc.numPages;
        _this.renderPage(_this.pageNum);
      });
    },
    renderPage(num) {
      // 渲染pdf
      const vm = this;
      this.pageRendering = true;
      const canvas = document.getElementById("the-canvas");
      // Using promise to fetch the page
      this.pdfDoc.getPage(num).then(function(page) {
        var viewport = page.getViewport(vm.scale);
        // alert(vm.canvas.height)
        canvas.height = viewport.height;
        vm.pdfWidth = canvas.width = viewport.width;
        // Render PDF page into canvas context
        var renderContext = {
          canvasContext: vm.ctx,
          viewport: viewport
        };
        var renderTask = page.render(renderContext);
        // Wait for rendering to finish
        renderTask.promise.then(function() {
          vm.pageRendering = false;
          if (vm.pageNumPending !== null) {
            // New page rendering is pending
            vm.renderPage(vm.pageNumPending);
            vm.pageNumPending = null;
          }
        });
      });
      vm.page_num = vm.pageNum;
    },
    addscale() {
      // 放大
      if (this.scale >= this.maxscale) {
        return;
      }
      this.scale += 0.5;
      this.queueRenderPage(this.pageNum);
    },
    minus() {
      // 缩小
      if (this.scale <= this.minscale) {
        return;
      }
      this.scale -= 0.5;
      this.queueRenderPage(this.pageNum);
    },
    prev() {
      // 上一页
      const vm = this;
      if (vm.pageNum <= 1) {
        return;
      }
      vm.pageNum--;
      vm.queueRenderPage(vm.pageNum);
    },
    next() {
      // 下一页
      const vm = this;
      if (vm.pageNum >= vm.page_count) {
        return;
      }
      vm.pageNum++;
      vm.queueRenderPage(vm.pageNum);
    },
    queueRenderPage(num) {
      if (this.pageRendering) {
        this.pageNumPending = num;
      } else {
        this.renderPage(num);
      }
    }
  },
  created() {
    this.title = this.$route.query.title;
  },
  mounted() {
    // if (
    //   this.$route.query.agreementPdfUrl != null &&
    //   this.$route.query.agreementPdfUrl != ""
    // ) {
      const CMAP_URL = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/cmaps/";
      let obj = {};
      obj.url = "https://rrnami.oss-cn-shanghai.aliyuncs.com/storehouse/%E6%8E%A8%E5%B9%BF%E6%9C%8D%E5%8A%A1%E6%89%BF%E6%8F%BD%E5%8D%8F%E8%AE%AE.pdf"; //pdf链接地址
      // obj.url = this.$route.query.agreementPdfUrl; //pdf链接地址
      // "http://ylsite.oss-cn-hangzhou.aliyuncs.com/storehouse/2019/12/bOsHRP2T9bhgDA2Dv3XUO7tuekDiH0PZ2uABWyVtQVXOABrz.pdf";
      obj.cMapUrl = CMAP_URL;
      obj.cMapPacked = true;
      this.init(obj);
    // } else {
    //   this.flag = false;
    //   this.$toast("PDF文件是空的哦~");
    // }
  }
};
</script>
<style scoped>
.center {
}
canvas {
  width: 100%;
}
.contor {
  text-align: center;
  line-height: 1.375rem;
  color: #c9431f;
}
</style>
