// 支付宝账号预验证入口
<template>
  <div class="content">
    <van-nav-bar
      class="nar-bar-info"
      title="支付宝账号验证"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="pre-verification">
      <div class="title">
        <span></span>
        中国大陆身份信息
      </div>
      <div class="form-item">
        <div><label>姓名：</label>{{ userName }}</div>
        <div><label>证件类型：</label>{{ idCardType }}</div>
        <div><label>证件号：</label>{{ idCardValue }}</div>
      </div>

      <div class="title">
        <span></span>
        支付宝账户信息
      </div>
      <div class="form-item">
        <div><label>支付宝账号：</label>{{ alipayAccount }}</div>
      </div>
    </div>
    <div class="btn-submit">
      <van-button class="submit" @click="verificationFn" type="primary"
        >验证
      </van-button>
    </div>
    <!--    <van-dialog
          v-model:show="dialogShow"
          title="提示"
          :message="errorMsg"
          confirm-button-text="我知道了"
          confirm-button-color="#468cff"
        >
        </van-dialog>-->
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getUserMsg, authAndVerify } from "../api/index.js";
import { Dialog } from "vant";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const alipayData = reactive({
      userName: "", //姓名
      idCardValue: "", //证件号
      idCardType: "身份证", //证件类型
      alipayAccount: "", //支付宝账号
      dialogShow: false,
      errorMsg: "",
      userId: 0,
      authCode: "",
    });

    const verificationFn = async () => {
      let _params = {
        userId: alipayData.userId,
        authCode: alipayData.authCode,
      };
      authAndVerify(_params).then((res) => {
        if (res.data.code == "10000" && res.code == 200) {
          router.push({
            path: "/validationResults",
            query: res.data,
          });
        } else {
          Dialog({
            title: "提示",
            message: res.data.subMsg ? res.data.subMsg : res.message,
            confirmButtonColor: "#468cff",
          });
        }
      });
    };
    //返回首页
    const onClickLeft = () => {
      router.push({
        name: "alipayInfoCollection",
      });
    };
    onMounted(() => {
      alipayData.userId = route.query.state;
      alipayData.authCode = route.query.auth_code;
      // 获取本地支付宝账户信息
      let _params = {
        userId: alipayData.userId,
      };
      getUserMsg(_params).then((res) => {
        if (res.code == 200) {
          alipayData.userName = res.data.userName;
          alipayData.idCardValue = res.data.idCardValue;
          alipayData.alipayAccount = res.data.alipayAccount;
        } else {
          alipayData.dialogShow = true;
          alipayData.errorMsg = "暂无数据!";
        }
      });
    });
    return {
      ...toRefs(alipayData),
      verificationFn,
      onClickLeft,
    };
  },
});
</script>
<style scoped>
</style>
