// 支付宝账号预验证入口
<template>
  <div>
    <van-nav-bar
      class="nar-bar-info"
      title="支付宝账号验证"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="pre-verification">
      <div class="title">
        <span></span>
        支付宝账号验证结果
      </div>
      <div class="result-item">
        <div v-if="passed" class="result-item-box">
          <img
            src="../assets/img/photo_success@2x.png"
            alt=""
            width="150"
            height="150"
          />
          <div class="item-info">验证通过</div>
        </div>
        <div v-else class="result-item-box">
          <img
            src="../assets/img/photo_fail@2x.png"
            alt=""
            width="150"
            height="150"
          />
          <div class="item-info">验证不通过</div>
          <div class="item-info">{{ reason }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      reason: "",
      passed: false,
    });
    //返回到首页
    const onClickLeft = () => {
      router.push({
        name: "alipayInfoCollection",
      });
    };
    onMounted(() => {
      let result = route.query;
      data.reason = result.failReason;
      data.passed = result.passed == "T" ? true : false;
    });
    return {
      ...toRefs(data),
      onClickLeft,
    };
  },
});
</script>
<style scoped>
.result-item {
  margin-top: 45%;
}
.result-item-box {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.result-item .item-info {
  text-align: center;
}
</style>
